@keyframes Gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-size: 200% 200%;

    animation: Gradient 10s ease infinite;

    z-index: -1;

    background-image: linear-gradient(-45deg, #b721ff, #21d4fd);
}




.App {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  opacity: 0;
  transition: opacity 1s;

  color: white;
}

.loaded {
  opacity: 1;
}

a {
  color: white;
}

.info-text {
  margin: 12px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2;

  opacity: 0.5;
}

.content-container {
  width: 320px;
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-container {
  padding: 30px;
}
.icon-container > img {
  width: 220px;
  height: 220px;
}

.slogan {
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
}

.social-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 40px;
}

.social-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2;

  transition: font-weight 100ms ease-in-out, font-size 100ms ease-in-out;
}

.social:hover {
  font-weight: 800;
  font-size: 18px;
}


@import url(https://rsms.me/inter/inter-ui.css);
html { font-family: 'Inter UI', sans-serif !important; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter UI var alt', sans-serif !important; }
}

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;

  color: white;
}

.loaded {
  opacity: 1;
}

a {
  color: white;
}

.info-text {
  margin: 12px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2;

  opacity: 0.5;
}

.content-container {
  width: 320px;
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-container {
  padding: 30px;
}
.icon-container > img {
  width: 220px;
  height: 220px;
}

.slogan {
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
}

.social-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 40px;
}

.social-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2;

  -webkit-transition: font-weight 100ms ease-in-out, font-size 100ms ease-in-out;

  transition: font-weight 100ms ease-in-out, font-size 100ms ease-in-out;
}

.social:hover {
  font-weight: 800;
  font-size: 18px;
}


@-webkit-keyframes Gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes Gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-size: 200% 200%;

    -webkit-animation: Gradient 10s ease infinite;

            animation: Gradient 10s ease infinite;

    z-index: -1;

    background-image: -webkit-linear-gradient(135deg, #b721ff, #21d4fd);

    background-image: linear-gradient(-45deg, #b721ff, #21d4fd);
}





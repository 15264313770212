@import url('https://rsms.me/inter/inter-ui.css');
html { font-family: 'Inter UI', sans-serif !important; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter UI var alt', sans-serif !important; }
}

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
